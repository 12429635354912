const styles = (theme) => ({
  wrapper: {
    marginBottom: 24,
  },
  tab: {
    display: 'none',
    '&.active': {
      display: 'block',
    },
  },
});

export default styles;
