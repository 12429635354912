const styles = (theme) => ({
  wrapper: {
    padding: '16px 0px',
  },
  itemRow: {
    padding: '16px 0',
    margin: '0 -8px',
    width: 'calc(100% + 16px)',
  },
  btnWrapper: {
    width: '100%',
    textAlign: 'center',
  },
  allBranchesBtn: {
    textTransform: 'none',
    fontWeight: theme.typography.fontWeightRegular,
    marginTop: '24px',
    '& .MuiButton-iconSizeMedium': {
      backgroundColor: theme.palette.common.purple400,
      padding: 2,
      borderRadius: '100%',
    },
    '@media(max-width: 767px)': {
      fontSize: theme.typography.fontSize14,
    },
  },
});

export default styles;
