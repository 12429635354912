const styles = (theme) => ({
  wrapper: {
    marginTop: 16,
  },
  titleWrapper: {
    marginBottom: 16,
  },
  title: {
    color: theme.palette.common.blue400,
    fontSize: theme.typography.fontSize28,
    lineHeight: 1.3,
    fontWeight: theme.typography.fontWeightSemiBold,
    marginBottom: 4,
    '@media(max-width: 767px)': {
      fontSize: theme.typography.fontSize20,
    },
  },
  tabBg: {
    backgroundColor: theme.palette.common.white,
    borderTop: `1px solid ${theme.palette.common.blue50}`,
    borderBottom: `1px solid ${theme.palette.common.blue50}`,
  },
  tab: {
    padding: '24px 0',
    color: theme.palette.text.placeholder,
    fontSize: theme.typography.fontSize14,
    lineHeight: 1.5,
    fontWeight: theme.typography.fontWeightBold,
    opacity: 1,
    minWidth: 'auto',
    marginRight: '8vw',
    textTransform: 'uppercase',
    '&.Mui-selected': {
      color: theme.palette.common.blue400,
      fontWeight: theme.typography.fontWeightBold,
      fontSize: theme.typography.fontSize18,
    },
    '&:not(last-child)': {
      marginRight: 24,
    },
  },
  indicator: {
    backgroundColor: theme.palette.common.blue400,
    width: 56,
    maxWidth: 56,
    height: 3,
  },
  flexContainer: {
    gap: '40px',
    overflow: 'auto',
  },
});

export default styles;
