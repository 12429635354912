const styles = (theme) => ({
  wrapper: {
    backgroundColor: theme.palette.common.white,
    filter: 'drop-shadow(0px 0px 1px rgba(0, 0, 0, 0.25))',
    zIndex: theme.zIndex.zIndex10,
  },
  navigation: {
    padding: 0,
    display: 'flex',
    justifyContent: 'space-between',
  },
  navigationItem: {
    padding: 0,
    width: 'auto',
    '&:not(:last-child)': {
      paddingRight: 16,
    },
  },
  navLink: {
    position: 'relative',
    padding: '24px 0',
    display: 'block',
    color: theme.palette.common.grey300,
    fontSize: theme.typography.fontSize15,
    lineHeight: 1.4,
    cursor: 'pointer',
    '&.active': {
      color: theme.palette.common.blue400,
      fontWeight: theme.typography.fontWeightSemiBold,
    },
    '&.active:after': {
      content: '""',
      width: 56,
      position: 'absolute',
      height: 2,
      background: theme.palette.common.blue400,
      left: 0,
      bottom: 0,
    },
  },
});

export default styles;
