import React from 'react';
import { useTranslation } from 'react-i18next';
import Link from 'next/link';
import _get from 'lodash/get';

import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';

import { makeStyles } from 'tss-react/mui';

import ENDPOINTS from '@lumirental/lumi-web-sdk/dist/constants/app/ENDPOINTS';
import LIST_THRESHOLD from '@lumirental/lumi-web-shared/lib/constants/app/LIST_THRESHOLD';
import SEO_PAGE_ROUTES from '@lumirental/lumi-web-shared/lib/constants/app/SEO_PAGE_ROUTES';
import getLocaleRoute from '@lumirental/lumi-web-shared/lib/utils/getLocaleRoute';

import { onTapEvent } from '@/utils/gtm';

import BranchList from '@/components/Lists/Branch';

import styles from '@/blocks/CityBranch/CityBranchBlock.style';

const useStyles = makeStyles()(styles);

export default function CityBranchBlock({ appEnv, cityBranchList }) {
  const { classes } = useStyles();
  const { t, i18n } = useTranslation();
  const { language } = i18n;
  // set initial state
  const listCountThreshold = LIST_THRESHOLD.CITY_BRANCH / 2;
  const slicedCityBranchList = cityBranchList.slice(0, listCountThreshold);

  // derive
  const baseURL = ENDPOINTS.BASE_URL[appEnv];
  const viewAllUrl = `${getLocaleRoute(language, SEO_PAGE_ROUTES.CITY_BRANCH)}`;

  const showViewAllBtn = cityBranchList.length !== slicedCityBranchList.length;

  const handleCityBranchClick = (cityBranch) => {
    const cityBranchSlug = _get(cityBranch, 'slug', '');
    // send GTM event
    onTapEvent('Home', `${cityBranchSlug}_tapped`);
  };
  const sendGTMEvent = () => {
    // send GTM event
    onTapEvent('Home', `view_all_city_branch_tapped`);
  };

  return (
    <div className={classes.wrapper}>
      <Container>
        <Grid container>
          <>
            <Grid container className={classes.itemRow}>
              <BranchList
                listData={slicedCityBranchList}
                baseURL={baseURL}
                branchRoute={SEO_PAGE_ROUTES.CITY_BRANCH}
                language={language}
                handleBranchClick={handleCityBranchClick}
              />
            </Grid>
            {showViewAllBtn && (
              <div className={classes.btnWrapper}>
                <Link
                  passHref
                  href={viewAllUrl}
                  className={classes.allBranchesBtn}
                  legacyBehavior
                >
                  <Button
                    className={classes.allBranchesBtn}
                    alt={t('view_all_web')}
                    title={t('view_all_web')}
                    onClick={sendGTMEvent}
                    target="_blank"
                  >
                    {t('view_all_web')}
                  </Button>
                </Link>
              </div>
            )}
          </>
        </Grid>
      </Container>
    </div>
  );
}
