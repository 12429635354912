import React from 'react';
import _get from 'lodash/get';

import { Typography } from '@mui/material';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';

import { makeStyles } from 'tss-react/mui';

import styles from './BranchesTab.style';

const useStyles = makeStyles()(styles);

export default function BranchesTab({
  tabs,
  title,
  selectedTab,
  handleChangeTab,
}) {
  const { classes } = useStyles();

  return (
    <div className={classes.wrapper}>
      <Container>
        <Grid container className={classes.container}>
          <Grid item sm={12}>
            {title && (
              <div className={classes.titleWrapper}>
                <Typography variant="h2" className={classes.title}>
                  {title}
                </Typography>
              </div>
            )}
          </Grid>
        </Grid>
      </Container>
      <div className={classes.tabBg}>
        <Container>
          <Grid container>
            <Grid item sm={12} sx={{ overflow: 'hidden' }}>
              <Tabs
                classes={{
                  indicator: classes.indicator,
                  flexContainer: classes.flexContainer,
                }}
                value={selectedTab}
                onChange={handleChangeTab}
              >
                {tabs &&
                  tabs.map((tabItem) => {
                    const id = _get(tabItem, 'id', 0);
                    const label = _get(tabItem, 'title', '');
                    return (
                      <Tab
                        key={id}
                        label={label}
                        classes={{
                          root: classes.tab,
                        }}
                      />
                    );
                  })}
              </Tabs>
            </Grid>
          </Grid>
        </Container>
      </div>
    </div>
  );
}
