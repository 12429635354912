import React from 'react';
import { useTranslation } from 'react-i18next';
import Link from 'next/link';
import _get from 'lodash/get';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import { makeStyles } from 'tss-react/mui';

import getLocaleRoute from '@lumirental/lumi-web-shared/lib/utils/getLocaleRoute';

import styles from '@/components/Lists/Branch/BranchList.style';

const useStyles = makeStyles()(styles);

export default function BranchList({
  listData,
  hasNoData,
  language,
  branchRoute,
  handleBranchClick,
}) {
  const { classes } = useStyles();
  const { t, i18n } = useTranslation();
  const { language: lang } = i18n;
  if (hasNoData) {
    return (
      <Typography variant="h6" className={classes.noOfferMsg}>
        {t('nothing_found')}
      </Typography>
    );
  }
  return (
    <>
      {listData &&
        listData.map((branch) => {
          const id = _get(branch, 'branchId', 0);
          const branchName = branch?.name?.[lang] ?? branch?.name;
          const branchSlug = branch?.slug ?? branchName;
          const branchKey = `${id}_${branchSlug}`;
          const branchPath = `${branchRoute}/${branchSlug}`;
          const abslUrl = `${getLocaleRoute(
            language,
            branchPath,
          )}`?.toLowerCase();
          return (
            <Grid
              key={branchKey}
              item
              sm={6}
              md={4}
              lg={3}
              className={classes.listItem}
            >
              <Link
                onClick={() => handleBranchClick(branch)}
                alt={branchName}
                title={branchName}
                target="_blank"
                href={abslUrl}
              >
                <Typography className={classes.label}>{branchName}</Typography>
              </Link>
            </Grid>
          );
        })}
    </>
  );
}
