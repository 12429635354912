'use client';

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Typography from '@mui/material/Typography';

import { makeStyles } from 'tss-react/mui';

import { manageSmoothScrolling } from '@/utils';
import { onTapEvent } from '@/utils/gtm';

import { HOME_SUB_MENUS } from '@/constants';

import styles from '@/blocks/HeaderNavbar/HeaderNavbarBlock.style';

const useStyles = makeStyles()(styles);

export default function HeaderNavbarBlock() {
  const [selectedMenu, setSelectedMenu] = useState(HOME_SUB_MENUS.WHATS_NEW);

  const { classes } = useStyles();
  const { t } = useTranslation();

  const handleClick = (selectedMenu, selectedScreen) => {
    // send GA event
    onTapEvent(`home_${selectedScreen}`, `${selectedScreen}_tapped`);

    // manage Smooth Scrolling.
    const eleId = `#${selectedMenu}`;
    manageSmoothScrolling(eleId);
    setSelectedMenu(selectedMenu);
  };

  const checkActiveClass = (currentMenu) => {
    return selectedMenu === currentMenu
      ? `${classes.navLink} active`
      : classes.navLink;
  };

  return (
    <div className={classes.wrapper}>
      <Container>
        <Grid container>
          <Grid item sm={12}>
            <List className={classes.navigation}>
              <ListItem className={classes.navigationItem}>
                <Typography
                  className={checkActiveClass(HOME_SUB_MENUS.WHATS_NEW)}
                  onClick={() =>
                    handleClick(HOME_SUB_MENUS.WHATS_NEW, 'whatshot')
                  }
                >
                  {t('What’s_hot')}
                </Typography>
              </ListItem>
              <ListItem className={classes.navigationItem}>
                <Typography
                  className={checkActiveClass(HOME_SUB_MENUS.WHY_LUMI)}
                  onClick={() =>
                    handleClick(HOME_SUB_MENUS.WHY_LUMI, 'why_lumi')
                  }
                >
                  {t('Why_lumi')}
                </Typography>
              </ListItem>
              <ListItem className={classes.navigationItem}>
                <Typography
                  className={checkActiveClass(HOME_SUB_MENUS.OUR_STEP)}
                  onClick={() =>
                    handleClick(HOME_SUB_MENUS.OUR_STEP, 'seamless_boooking')
                  }
                >
                  {t('Seamless_booking_process')}
                </Typography>
              </ListItem>
              <ListItem className={classes.navigationItem}>
                <Typography
                  className={checkActiveClass(HOME_SUB_MENUS.OUR_LOCATION)}
                  onClick={() =>
                    handleClick(HOME_SUB_MENUS.OUR_LOCATION, 'our_location')
                  }
                >
                  {t('Our_locations_home_screen')}
                </Typography>
              </ListItem>
              <ListItem className={classes.navigationItem}>
                <Typography
                  className={checkActiveClass(HOME_SUB_MENUS.VIEW_APP)}
                  onClick={() =>
                    handleClick(HOME_SUB_MENUS.VIEW_APP, 'view_our_app')
                  }
                >
                  {t('View_our_app')}
                </Typography>
              </ListItem>
            </List>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}
