const styles = (theme) => ({
  wrapper: {
    padding: '16px 0px',
  },
  itemRow: {
    padding: 0,
    margin: '0 -8px',
    width: 'calc(100% + 16px)',
  },
  container: {
    paddingBottom: 12,
    borderBottom: `1px solid ${theme.palette.common.blue50}`,
  },

  titleWrapper: {
    marginBottom: 12,
  },
  title: {
    color: theme.palette.common.blue400,
    fontSize: theme.typography.fontSize28,
    lineHeight: 1.3,
    fontWeight: theme.typography.fontWeightSemiBold,
    marginBottom: 4,
    '@media(max-width: 767px)': {
      fontSize: theme.typography.fontSize20,
    },
  },
  cityRow: {
    padding: '12px 0',
    margin: '0 -8px',
    width: 'calc(100% + 16px)',
  },
  cityCol: {
    width: '100%',
    padding: '12px 8px',
  },
  btnWrapper: {
    width: '100%',
    textAlign: 'center',
    margin: '12px 0px',
  },
  allBranchesBtn: {
    textTransform: 'none',
    '& .MuiButton-iconSizeMedium': {
      width: 24,
      height: 24,
      backgroundColor: theme.palette.common.purple100,
      padding: 6,
      borderRadius: '100%',
    },
  },
});

export default styles;
